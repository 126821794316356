import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Link from "gatsby-link"

const Template = ({ data }) => {
  const post = data.markdownRemark

  return (
    <div className="blog-post">
      <Link to="/blog">All Posts</Link>
      <SEO title={post.frontmatter.title} />
      <div className="blog-content">
        <h1>{post.frontmatter.title}</h1>
        <small className="date">
          {post.frontmatter.author} on {post.frontmatter.date}
        </small>
        <div
          className="blog-text"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </div>
    </div>
  )
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        author
        date
      }
    }
  }
`

export default Template
